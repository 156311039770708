import React, { lazy, Suspense, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useWalletAuth from "./hooks/useWalletAuth";
import LoadingPage from "./views/LoadingPage"

const ApplicationHome = lazy(() => import("./views/ApplicationHome"));
const Me = lazy(() => import("./views/Me"));
const NotFound = lazy(() => import("./views/404"));
const Tools = lazy(() => import("./views/Tools"));
const Drops = lazy(() => import("./views/Drops"));
const NFTExporter = lazy(() => import("./views/Tools/NFT_Exporter"));
const GIFGenerator = lazy(() => import("./views/Tools/GIF_Generator"));
const PFPGenerator = lazy(() => import("./views/Tools/PFP_Generator"));
const CollectionGenerator = lazy(() => import("./views/Tools/Collection_Generator"));
const SolkeletonArmyLaunch = lazy(() => import("./views/Drops/SolkeletonArmyLaunch"));

export const AuthContext = createContext<any>(null);

const RoutesComponent: React.FC = React.memo(() => {
  const auth = useWalletAuth();
  return (
    <AuthContext.Provider value={auth}>
      <Router>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route index element={<ApplicationHome />} />
            <Route path="/me" element={<Me />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/tools/nft-exporter" element={<NFTExporter />} />
            <Route path="/tools/gif-generator" element={<GIFGenerator />} />
            <Route path="/tools/hex-pfp-generator" element={<PFPGenerator />} />
            <Route path="/tools/collection-generator" element={<CollectionGenerator />} />
            <Route path="/drops" element={<Drops />} />
            <Route path="/drops/solkeleton-army" element={<SolkeletonArmyLaunch />} />
          </Routes>
        </Suspense>
      </Router>
    </AuthContext.Provider>
  );
})
export default RoutesComponent;