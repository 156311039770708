import React from "react";
import ReactDOM from "react-dom/client";
import { useMemo } from "react";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import  { Coin98WalletAdapter, LedgerWalletAdapter, MathWalletAdapter, PhantomWalletAdapter, SafePalWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, SolletExtensionWalletAdapter, SolletWalletAdapter, TorusWalletAdapter }from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import "./index.css";
import Routes from "./routes";
import '@solana/wallet-adapter-react-ui/styles.css';
import { MantineProvider } from "@mantine/core";

const network =  WalletAdapterNetwork.Mainnet;

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new Coin98WalletAdapter(),
      new MathWalletAdapter(),
      new SafePalWalletAdapter(), 
      new SolletExtensionWalletAdapter({ network }),
      new TorusWalletAdapter()
    ],
    []
  );
  return (
    <MantineProvider
    inherit
    theme={{
      colorScheme: "dark",
    }}
  >
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={true}>
        <WalletModalProvider>
          <Routes />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
    </MantineProvider>
  );
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);
root.render(
    <App />
);