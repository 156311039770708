import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import bs58 from 'bs58';
import axios from "axios";
import { v5 as uuidv5} from "uuid";

const useWalletAuth = () => {
    const [auth, setAuth] = useState(false);
    const wallet = useWallet();
    useEffect(() => {
        const fetchNFTs = async () => {
            if (wallet.publicKey && wallet.signMessage) {
                const tempRandomizedUUID = uuidv5(process.env.REACT_APP_RANDOM_STRING!, process.env.REACT_APP_NONCE_NAMESPACE!);
                const randomizedUUID = uuidv5(wallet.publicKey.toString(), tempRandomizedUUID);
                var headers = {
                    'authorization': randomizedUUID,
                    'Content-Type': 'application/json',
                };
                const messageRequest = await axios.post("https://api.solkeletonarmy.com/user/random-message-generator", {walletAddress:wallet.publicKey.toString()} , { headers})
                messageRequest.data.uuid && localStorage.setItem("uuid",messageRequest.data.uuid);
                if(!messageRequest.data.verified){
                    const message = await new TextEncoder().encode(`Welcome to Solkeleton Army!\n\nThis request won't trigger any transaction or cost any gas fees.\n\nThis authentication status will be reseted after 24 hours.\n\nWallet Address:\n${wallet.publicKey}\n\nNonce:\n${messageRequest.data.uuid}`)
                    await wallet.signMessage!(message).then(async (res) => {
                        headers={
                            'authorization': localStorage.getItem("uuid")!,
                            'Content-Type': 'application/json',
                        }
                        const verifieRequest = await axios.post("https://api.solkeletonarmy.com/user/verify", { walletAddress: wallet.publicKey!.toString(), signature: bs58.encode(res) }, { headers})
                        if(verifieRequest.data.success){
                            setAuth(true);
                        }else{
                            setAuth(false);
                        }
                    }).catch(() => {
                        wallet.disconnect();
                    })
                }else{
                    setAuth(true);
                }
            }else{
                setAuth(false);
            }
        }
        fetchNFTs()
    }, [wallet.publicKey]);
    return auth;
};

export default useWalletAuth;